export const getCookie = (key) => {
  try {
    let result;
    if ((result = new RegExp(`(?:^|; )${encodeURIComponent(key)}=([^;]*)`).exec(document.cookie))) {
      return decodeURIComponent(result[1]);
    }
    return null;
  } catch (e) {
    if (/sandbox/i.test(e.message)) {
      console.warn("sandboxed, can't get cookie");
      return null;
    }
    throw e;
  }
};
